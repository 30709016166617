









































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { Role } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { MUT_SNACKBAR } from '@/store';

@Component({
  apollo: {
    roles: {
      query: gql`
        query getRoles($orgId: ID!) {
          roles(orgId: $orgId) {
            id
            name
            description
            scopes
            editable
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
    // scopeDefinitions: {
    //   query: gql`
    //     query getScopeDefinitions {
    //       scopeDefinitions {
    //         scope
    //         friendlyEntity
    //         friendlySubEntity
    //         permissionType
    //         description
    //         requiredOrgFlags
    //         groups
    //       }
    //     }
    //   `,
    //   result(result) {
    //     if (result.data.scopeDefinitions) {
    //       const items = result.data.scopeDefinitions;
    //       const itemsMap = new Map<string, any>();
    //       for (const item of items) {
    //         const parentItemKey = item.friendlyEntity;
    //         const itemKey = item.friendlySubEntity === null ?
    //                               item.friendlyEntity :
    //                               `${item.friendlyEntity}-${item.friendlySubEntity}`;
    //         const itemName = item.friendlySubEntity ?? item.friendlyEntity;
    //         let itemVal: any, parentItemVal: any;

    //         if (!itemsMap.has(parentItemKey)) {
    //           parentItemVal = {
    //             name: item.friendlyEntity,
    //             entity: item.friendlyEntity,
    //             subEntity: null,
    //             createEnabled: false,
    //             createValue: false,
    //             updateEnabled: false,
    //             updateValue: false,
    //             deleteEnabled: false,
    //             deleteValue: false,
    //             readEnabled: false,
    //             readValue: false,
    //           };

    //           itemsMap.set(parentItemKey, parentItemVal);
    //         }

    //         if (itemsMap.has(itemKey)) {
    //           itemVal = itemsMap.get(itemKey);
    //         } else {
    //           itemVal = {
    //             name: itemName,
    //             entity: item.friendlyEntity,
    //             subEntity: item.friendlySubEntity,
    //             createEnabled: false,
    //             createValue: false,
    //             updateEnabled: false,
    //             updateValue: false,
    //             deleteEnabled: false,
    //             deleteValue: false,
    //             readEnabled: false,
    //             readValue: false,
    //           };
    //         }

    //         switch (item.permissionType) {
    //           case 'create':
    //             itemVal['createEnabled'] = true;
    //             itemVal['createValue'] = false;
    //             itemVal['createScope'] = item.scope;
    //             break;
    //           case 'update':
    //             itemVal['updateEnabled'] = true;
    //             itemVal['updateValue'] = false;
    //             itemVal['updateScope'] = item.scope;
    //             break;
    //           case 'delete':
    //             itemVal['deleteEnabled'] = true;
    //             itemVal['deleteValue'] = false;
    //             itemVal['deleteScope'] = item.scope;
    //             break;
    //           case 'read':
    //             itemVal['readEnabled'] = true;
    //             itemVal['readValue'] = false;
    //             itemVal['readScope'] = item.scope;
    //             break;
    //         }

    //         itemsMap.set(itemKey, itemVal);
    //       }

    //       this.scopeDefinitions = Array.from(itemsMap.values());
    //     }
    //   },
    // },
  },
  components: {
    UiButton,
    UiDataTable,
    UiLoading,
    UiTextEdit,
    UiCheckbox,
  },
})
export default class Roles extends BaseVue {
  public isLoading = 0;
  public isScopesLoading = 0;
  public isCreatingRole = false;
  public isSaving = false;

  public headers = [
    {
      id: 'name',
      label: 'Name',
      defaultVisibility: true,
      selector: (item: Role) => item.name,
    },
    {
      id: 'description',
      label: 'Description',
      defaultVisibility: true,
      selector: (item: Role) => item.description,
    },
  ];

  public scopeHeaders = [
    {
      id: 'entity',
      label: 'Entity',
      defaultVisibility: true,
      defaultWidth: '600px',
    },
    {
      id: 'read',
      label: 'Read',
      defaultVisibility: true,
    },
    {
      id: 'create',
      label: 'Create',
      defaultVisibility: true,
    },
    {
      id: 'update',
      label: 'Update',
      defaultVisibility: true,
    },
    {
      id: 'delete',
      label: 'Delete',
      defaultVisibility: true,
    },
  ];

  public currentRole: any = {
    id: undefined,
    name: '',
    description: '',
    scopes: [],
  };

  scopeDefinitions: any[] = [];

  public async fetchScopeDefinitions() {
    const resp: any = await this.$apollo
      .query({
        query: gql`
          query getScopeDefinitions {
            scopeDefinitions {
              scope
              friendlyEntity
              friendlySubEntity
              permissionType
              description
              requiredOrgFlags
              groups
            }
          }
        `,
      })
      .catch((error) => {
        console.log(error.message);
        this.showSnackbar('error', error.message);
        return { error: error.message };
      });

    if (resp.data.scopeDefinitions) {
      const items = resp.data.scopeDefinitions;
      const itemsMap = new Map<string, any>();
      for (const item of items) {
        const parentItemKey = item.friendlyEntity;
        const itemKey =
          item.friendlySubEntity === null ? item.friendlyEntity : `${item.friendlyEntity}-${item.friendlySubEntity}`;
        const itemName = item.friendlySubEntity ?? item.friendlyEntity;
        let itemVal: any, parentItemVal: any;

        if (!itemsMap.has(parentItemKey)) {
          parentItemVal = {
            name: item.friendlyEntity,
            entity: item.friendlyEntity,
            subEntity: null,
            createEnabled: false,
            createValue: false,
            updateEnabled: false,
            updateValue: false,
            deleteEnabled: false,
            deleteValue: false,
            readEnabled: false,
            readValue: false,
          };

          itemsMap.set(parentItemKey, parentItemVal);
        }

        if (itemsMap.has(itemKey)) {
          itemVal = itemsMap.get(itemKey);
        } else {
          itemVal = {
            name: itemName,
            entity: item.friendlyEntity,
            subEntity: item.friendlySubEntity,
            createEnabled: false,
            createValue: false,
            updateEnabled: false,
            updateValue: false,
            deleteEnabled: false,
            deleteValue: false,
            readEnabled: false,
            readValue: false,
          };
        }

        switch (item.permissionType) {
          case 'create':
            itemVal.createEnabled = true;
            itemVal.createValue = false;
            itemVal.createScope = item.scope;
            break;
          case 'update':
            itemVal.updateEnabled = true;
            itemVal.updateValue = false;
            itemVal.updateScope = item.scope;
            break;
          case 'delete':
            itemVal.deleteEnabled = true;
            itemVal.deleteValue = false;
            itemVal.deleteScope = item.scope;
            break;
          case 'read':
            itemVal.readEnabled = true;
            itemVal.readValue = false;
            itemVal.readScope = item.scope;
            break;
        }

        itemsMap.set(itemKey, itemVal);
      }

      this.scopeDefinitions = Array.from(itemsMap.values());
    } else {
      this.showSnackbar('error', 'Problem fetching scope definitions');
    }

    return [];
  }

  public async onCreateRole() {
    await this.fetchScopeDefinitions();
    this.isCreatingRole = true;
  }

  async openEditPage(role: Role) {
    this.currentRole.id = role.id;
    this.currentRole.name = role.name;
    this.currentRole.description = role.description;

    await this.fetchScopeDefinitions();

    this.scopeDefinitions.map((m: any) => {
      if (role.scopes.includes(m.createScope)) {
        m.createValue = true;
      }

      if (role.scopes.includes(m.readScope)) {
        m.readValue = true;
      }

      if (role.scopes.includes(m.updateScope)) {
        m.updateValue = true;
      }

      if (role.scopes.includes(m.deleteScope)) {
        m.deleteValue = true;
      }
    });

    console.log(this.currentRole);

    this.isCreatingRole = true;
  }

  showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }

  public async onSave() {
    if (this.currentRole.name === '' || this.currentRole.description === '') {
      this.showSnackbar('warning', 'Please provide Name and Description.');
      return;
    }

    this.isSaving = true;

    const orgId = this.$store.state.currentOrg.id;

    this.scopeDefinitions.map((m: any) => {
      const scope = m.scope; // .replace('$orgId', orgId).replace('$walletId', '*');
      if (m.createValue) {
        this.currentRole.scopes.push(m.createScope);
      }
      if (m.readValue) {
        this.currentRole.scopes.push(m.readScope);
      }
      if (m.updateValue) {
        this.currentRole.scopes.push(m.updateScope);
      }
      if (m.deleteValue) {
        this.currentRole.scopes.push(m.deleteScope);
      }
    });

    const resp: any = await this.$apollo
      .mutate({
        mutation: gql`
          mutation SaveRole($orgId: ID!, $role: SaveRole!) {
            saveRole(orgId: $orgId, role: $role) {
              success
              errors
            }
          }
        `,
        variables: {
          orgId,
          role: this.currentRole,
        },
      })
      .catch((error) => {
        console.log(error.message);
        this.showSnackbar('error', error.message);
        this.isSaving = false;
        this.resetForm();
        return { error: error.message };
      });

    if (resp.data.saveRole.success) {
      this.showSnackbar('success', 'Role created successfully.');
    } else {
      this.showSnackbar('error', 'Problem creating role: ' + resp.data.saveRole.errors.join('<br />'));
    }

    this.isSaving = false;
    this.isCreatingRole = false;
    this.resetForm();
    this.$apollo.queries.roles.refetch();
  }

  public onCancel() {
    this.resetForm();
    this.isCreatingRole = false;
  }

  public resetForm() {
    this.currentRole.id = undefined;
    this.currentRole.name = '';
    this.currentRole.description = '';
    this.currentRole.scopes = [];
  }
}
